import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  BiPermissionService,
  ChartPdfDownloadDomainService,
  Dashboard,
  PermissionForEntityFactory,
  WorkbookActionsService,
  WorkbookDetailProjections,
  WorkbookListBreadcrumbService,
  Workspace,
} from '@selfai-platform/bi-domain';
import { AlertService, BiEntityPermission, DestroyService, ScrollableXViewComponent } from '@selfai-platform/shared';
import { BI_ROOT_ROUTE, BreadcrumbsMenuService, convertMessageToHtml } from '@selfai-platform/shell';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, catchError, combineLatest, map, of, take, takeUntil } from 'rxjs';
import { WorkspaceSearchToolboxComponentModule } from '../../../workspace';
import { WorkbookPinsComponent } from '../workbook-pins';

@Component({
  selector: 'selfai-platform-workbook-toolbar',
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    TooltipModule,
    TranslateModule,
    WorkspaceSearchToolboxComponentModule,
    MenuModule,
    SkeletonModule,
    WorkbookPinsComponent,
    ConfirmDialogModule,
    ScrollableXViewComponent,
  ],
  templateUrl: './workbook-toolbar.component.html',
  styleUrls: ['./workbook-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WorkbookListBreadcrumbService, DestroyService, ConfirmationService],
})
export class WorkbookToolbarComponent implements OnChanges, OnInit, OnDestroy {
  @Input() workspace: Workspace;
  @Input() workbook: WorkbookDetailProjections;
  @Input() selectedDashboard: Dashboard;
  @Input() dashboards: Dashboard[];

  @Output() dashboardDelete: EventEmitter<Dashboard> = new EventEmitter();
  @Output() dashboardClone: EventEmitter<Dashboard> = new EventEmitter();
  @Output() creatingDashboardShow: EventEmitter<boolean> = new EventEmitter();

  private workspacePermission: PermissionForEntityFactory;
  biRootRoute = BI_ROOT_ROUTE;
  biEntityPermission = BiEntityPermission;

  pdfDownloading = false;

  menuItems: MenuItem[] = [
    {
      label: this.translate.instant('msg.navbar.chart'),
      command: () => this.addWidget('NEW_CHART'),
    },
    {
      label: this.translate.instant('msg.navbar.filter'),
      command: () => this.addWidget('NEW_FILTER'),
    },
    {
      label: this.translate.instant('msg.navbar.text'),
      command: () => this.addWidget('NEW_TEXT'),
    },
  ];

  menuMoreItems$: Observable<MenuItem[]>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly permissionService: BiPermissionService,
    public readonly workbookActionsService: WorkbookActionsService,
    private readonly workbookListBreadcrumbService: WorkbookListBreadcrumbService,
    private readonly chartPdfDownloadDomainService: ChartPdfDownloadDomainService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly destroy$: DestroyService,
    private readonly translate: TranslateService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.menuMoreItems$ = combineLatest({
      cloneDashboard: this.translate.stream('msg.navbar.clone'),
      editDashboard: this.translate.stream('msg.navbar.edit'),
      deleteDashboard: this.translate.stream('msg.navbar.delete'),
      saveDashboardPdf: this.translate.stream('msg.navbar.saveDashboardPdf'),
    }).pipe(
      map(({ ...translations }) => {
        return this.getMenuMoreItems(translations);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedDashboard']) {
      this.workbookListBreadcrumbService
        .getBreadcrumbsMenu(this.workspace.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((breadcrumbsMenuItems) => [
          ...breadcrumbsMenuItems,
          ...[
            {
              label: this.workbook.name,
              icon: 'pi pi-book',
              routerLink: ['/', BI_ROOT_ROUTE, 'workbook', this.workbook.id],
              queryParams: { dashboardId: this.selectedDashboard.id },
              items: this.dashboards.map((dashboard) => {
                return {
                  label: dashboard.name,
                  icon: 'pi pi-chart-line',
                  routerLink: ['/', BI_ROOT_ROUTE, 'workbook', this.workbook.id],
                  queryParams: { dashboardId: dashboard.id },
                };
              }),
            },
            {
              label: this.selectedDashboard.name,
              icon: 'pi pi-chart-line',
              routerLink: ['/', BI_ROOT_ROUTE, 'workbook', this.workbook.id],
              queryParams: { dashboardId: this.selectedDashboard.id },
            },
          ],
        ]);
    }

    if (changes['workspace']) {
      this.workspacePermission = this.permissionService.getPermissionsForEntityFactory(this.workspace.permissions);
    }
  }

  ngOnDestroy(): void {
    this.breadcrumbsMenuService.clearBreadcrumbsMenu();
  }

  hasPermission(permission: BiEntityPermission): boolean {
    return this.workspacePermission?.hasPermission(permission);
  }

  public downloadPdf(): void {
    this.pdfDownloading = true;

    this.chartPdfDownloadDomainService
      .downloadDashboardPdf('dashboard.pdf', this.selectedDashboard.id)
      .pipe(
        take(1),
        catchError(() => {
          this.alertService.error(this.translate.instant('msg.dashboard.pdf-download.error'));

          return of();
        }),
      )
      .subscribe(() => {
        this.pdfDownloading = false;
        this.cdr.markForCheck();
      });
  }

  public addWidget(type: string) {
    switch (type) {
      case 'NEW_CHART':
        this.workbookActionsService.setUpdateDashboardConfig({ view: true, startupCmd: { cmd: 'NEW', type: 'CHART' } });
        break;
      case 'NEW_FILTER':
        this.workbookActionsService.setUpdateDashboardConfig({
          view: true,
          startupCmd: { cmd: 'NEW', type: 'FILTER' },
        });
        break;
      case 'NEW_TEXT':
        this.workbookActionsService.setUpdateDashboardConfig({ view: true, startupCmd: { cmd: 'NEW', type: 'TEXT' } });
        break;
    }
  }

  private getMenuMoreItems(
    translations: Record<'cloneDashboard' | 'editDashboard' | 'deleteDashboard' | 'saveDashboardPdf', string>,
  ): MenuItem[] {
    const items: MenuItem[] = [];

    if (this.hasPermission(BiEntityPermission.DASHBOARD_CREATE)) {
      items.push({
        label: translations.cloneDashboard,
        icon: 'pi pi-clone',
        command: () => this.dashboardClone.emit(this.selectedDashboard),
      });
    }

    if (this.hasPermission(BiEntityPermission.DASHBOARD_EDIT)) {
      items.push({
        label: translations.editDashboard,
        icon: 'pi pi-pencil',
        command: () => this.workbookActionsService.setUpdateDashboardConfig({ view: true }),
      });
    }

    if (this.hasPermission(BiEntityPermission.DASHBOARD_DELETE)) {
      items.push({
        label: translations.deleteDashboard,
        icon: 'pi pi-trash',
        command: () => this.confirmDeleteDashboard(this.selectedDashboard),
      });
    }
    if (this.hasPermission(BiEntityPermission.DASHBOARD_VIEW)) {
      items.push({
        label: translations.saveDashboardPdf,
        icon: 'pi pi-download',
        command: () => this.downloadPdf(),
      });
    }

    return items;
  }

  private confirmDeleteDashboard(dashboard: Dashboard): void {
    this.confirmationService.confirm({
      message: convertMessageToHtml(this.translate.instant('msg.dashboard.delete.confirmation')),
      header: this.translate.instant('msg.dashboard.delete.title'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      accept: () => {
        this.dashboardDelete.emit(dashboard);
      },
    });
  }
}

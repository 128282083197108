<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [showEntityIcon]="true"
  [columns]="columns"
  [groupActions]="groupActions$ | async"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
>
  <ng-container slot="toolbarActions">
    <p-menu #subMenu [model]="createMenu$ | async" [popup]="true" appendTo="body"></p-menu>
    <button
      *ngIf="canCreate"
      (click)="subMenu.toggle($event)"
      type="button"
      pButton
      icon="pi pi-plus-circle"
      class="p-button-success"
      appearance="icon"
      tooltipPosition="top"
      pTooltip="{{ 'data-source-list.toolbar.actions.create' | translate }}"
    ></button>
  </ng-container>
</selfai-platform-data-list-view>

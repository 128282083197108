import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { IsActiveMatchOptions, NavigationEnd, Router, UrlTree } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';
import { MenuItemsService, MenuService } from '../menu';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'selfai-platform-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MainMenuComponent implements OnInit {
  activeMenuItem$ = this.menuService.activeMenuItem$;
  menuMode$ = this.menuService.menuMode$;
  menuItems!: MenuItem[];

  get windowWidth(): number {
    return window.innerWidth
  }

  constructor(
    private readonly menuService: MenuService,
    private readonly menuItemService: MenuItemsService,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
  ) { }


  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (this.menuItems) {
        this.updateExpandedStatus();
      }
    });

    this.menuItemService.menu$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((menu) => {
      this.menuItems = menu;
      this.updateExpandedStatus();
    });
  }

  onMouseEnter(item: HTMLElement): void {
    this.menuService.setActiveMenuItem(item);
  }

  toogleMenu(item: MenuItem): void {
    item.expanded = !item.expanded;
  }

  isChildRouteActive(root: MenuItem): boolean | undefined {
    const matchOptions: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    };

    return root.items?.some((item) => {
      if (!item.routerLink) {
        return false;
      }

      const urlTree: UrlTree = Array.isArray(item.routerLink)
        ? this.router.createUrlTree(item.routerLink)
        : this.router.parseUrl(item.routerLink);

      return this.router.isActive(urlTree, matchOptions);
    });
  }

  updateExpandedStatus(): void {
    this.menuItems = this.menuItems.map((rootMenuItem: MenuItem) => {
      return {
        ...rootMenuItem,
        expanded: this.isChildRouteActive(rootMenuItem),
      };
    });
  }
}

<div
  class="thumbnail card border-1 surface-border flex flex-column m-0"
  [class.border-primary]="highlight"
  [class.surface-ground]="highlight"
>
  <div class="flex align-items-center space-between">
    <p-inputSwitch
      *ngIf="hasPermission(biEntityPermission.DASHBOARD_EDIT)"
      [ngModel]="isPinned"
      (ngModelChange)="togglePin($event)"
      [pTooltip]="(isPinned ? 'msg.workbook-pins.delete-pin' : 'msg.workbook-pins.pin-dashboard') | translate"
    ></p-inputSwitch>

    <div
      class="short-text font-bold w-100 mx-2 flex-grow-1"
      *ngIf="!isEditingNameDashboard; else editName"
      (click)="startEditDashboardName()"
    >
      {{ dashboard.name }}
    </div>

    <ng-template #editName>
      <input
        class="w-full text-base flex-grow-1"
        type="text"
        pInputText
        pAutoFocus
        [autofocus]="true"
        [(ngModel)]="dashboard.name"
        (keyup.enter)="renameDashboard()"
      />
    </ng-template>

    <ng-container *ngIf="menuMoreItems$ | async as menuMoreItems">
      <ng-container *ngIf="menuMoreItems.length">
        <p-button
          size="large"
          appearance="icon"
          icon="pi pi-cog"
          [text]="true"
          (click)="menu.toggle($event)"
          tooltipPosition="top"
          pTooltip="{{ 'msg.menu-actions-more.button-tooltip' | translate }}"
        ></p-button>
        <p-menu #menu [model]="menuMoreItems" [popup]="true" appendTo="body"></p-menu>
      </ng-container>
    </ng-container>
  </div>

  <div class="h-full">
    <selfai-platform-img-with-auth
      class="m-auto"
      (click)="dashboardSelect.emit(dashboard)"
      [src]="
        dashboard.imageUrl
          ? '/api/images/load/url?url=' + dashboard.imageUrl + '/thumbnail?' + now
          : thumbnailImgDefault
      "
    />
  </div>
</div>
<p-confirmDialog appendTo="body"></p-confirmDialog>

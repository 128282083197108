import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { ImgWithAuthComponent } from '@selfai-platform/shared';
import { DataListViewPaginationComponent } from '@selfai-platform/shell';
import { DropdownModule } from 'primeng/dropdown';
import { DashboardListSelectorComponentModule } from '../../../dashboard/components/dashboard-list-selector/dashboard-list-selector.module';
import { ChartListComponent } from './chart-list.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    AutoFocusModule,
    TooltipModule,
    ConfirmPopupModule,
    OverlayPanelModule,
    SkeletonModule,
    DialogModule,
    RouterModule,
    FormsModule,
    DashboardListSelectorComponentModule,
    TranslateModule,
    ImgWithAuthComponent,
    RouterModule.forChild([
      {
        path: '',
        component: ChartListComponent,
      },
    ]),
    DropdownModule,
    DataListViewPaginationComponent,
  ],
  declarations: [ChartListComponent],
  exports: [ChartListComponent],
})
export class ChartListComponentModule {}

import { Component } from '@angular/core';

@Component({
  selector: 'selfai-platform-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent {
  readonly logoPath = 'assets/images/logo_multiverse.png';
}

import { Pipe, PipeTransform } from "@angular/core";
import { detectLayout, Layout } from "../ui-settings/layout-device.util";

@Pipe({
  name:'selfaiPlatformLayout',
  standalone:true
})
export class LayoutPipe implements PipeTransform{
  transform(width: number): Layout {
    return detectLayout(width);
  }
}

<p-table
  #dt
  dataKey="id"
  [value]="dashboardList$ | async"
  [paginator]="true"
  [sortField]="sortField"
  [rows]="10"
  [sortOrder]="sortOrder"
  [loading]="loading$ | async"
  selectionMode="single"
  [(selection)]="selectedDashboard"
  [scrollable]="true"
  scrollHeight="50vh"
  [rowsPerPageOptions]="[10, 20, 30]"
  [globalFilterFields]="['name', 'workBook.name']"
>
  <ng-template pTemplate="loadingbody">
    <tr tabindex="-1">
      <td *ngFor="let _ of [].constructor(4)">
        <p-skeleton [ngStyle]="{ width: 100 / 4 + '%' }"></p-skeleton>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="caption">
    <div class="flex justify-content-between">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pAutoFocus
          [autofocus]="true"
          pInputText
          type="text"
          (input)="dt.filterGlobal($event, 'contains')"
          placeholder="Search"
        />
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="name">
        <p-sortIcon field="name"></p-sortIcon>
        Name
      </th>
      <th pSortableColumn="workBook.name">
        <p-sortIcon field="workBook.name"></p-sortIcon>
        Projects
      </th>
      <th pSortableColumn="createdTime">
        <p-sortIcon field="createdTime"></p-sortIcon>
        Created
      </th>
      <th pSortableColumn="modifiedTime">
        <p-sortIcon field="modifiedTime"></p-sortIcon>
        Modified
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item>
    <tr [pSelectableRow]="item" class="cursor-pointer" (dblclick)="onSelectDashboard(item)">
      <td>
        <ng-container *ngIf="item.imgUrl">
          <button
            type="button"
            pButton
            class="p-button-text p-button-link ml-2 p-0"
            appearance="icon"
            (click)="$event.stopPropagation(); overlayPanel.toggle($event)"
            icon="pi pi-search"
            tooltipPosition="left"
            pTooltip="Show preview"
          ></button>
          <p-overlayPanel appendTo="body" [dismissable]="true" [showCloseIcon]="true" #overlayPanel>
            <div class="preview">
              @if (overlayPanel.overlayVisible) {
                <selfai-platform-img-with-auth [src]="item.imageUrl" />
              }
            </div>
          </p-overlayPanel>
        </ng-container>
      </td>
      <td>{{ item.name }}</td>
      <td>{{ item.workBook.name }}</td>
      <td>{{ item.createdTime | date: 'dd.MM.yyyy, hh:mm' }}</td>
      <td>{{ item.modifiedTime | date: 'dd.MM.yyyy, hh:mm' }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <p-button
      *ngIf="selectedDashboard"
      pTooltip="Select dashboard"
      (click)="onSelectDashboard(selectedDashboard)"
      type="button"
      styleClass="p-button"
      [label]="textButton || 'Choose'"
    ></p-button>
  </ng-template>
</p-table>

<div class="p-tabview p-component p-tabview-scrollable">
  <div class="p-tabview-nav-container">
    @if (!backwardIsDisabled) {
      <button
        #prevBtn
        class="p-tabview-nav-prev p-tabview-nav-btn p-link"
        (click)="navBackward()"
        type="button"
        pRipple
      >
        <span class="pi pi-chevron-left"></span>
      </button>
    }

    <div #content class="p-tabview-nav-content" (scroll)="onScroll($event)">
      <ng-content></ng-content>
    </div>

    @if (!forwardIsDisabled) {
      <button #nextBtn class="p-tabview-nav-next p-tabview-nav-btn p-link" (click)="navForward()" type="button" pRipple>
        <span class="pi pi-chevron-right"></span>
      </button>
    }
  </div>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  BiPermissionService,
  ChartPdfDownloadDomainService,
  Dashboard,
  DashboardActionsService,
  DashboardDomainService,
  PermissionForEntityFactory,
  THUMBNAIL_IMAGE_DEFAULT,
  WorkbookActionsService,
  WorkbookPinsDomainService,
  Workspace,
} from '@selfai-platform/bi-domain';
import { AlertService, BiEntityPermission, DestroyService, ImgWithAuthComponent } from '@selfai-platform/shared';
import { BI_ROOT_ROUTE, convertMessageToHtml } from '@selfai-platform/shell';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, combineLatest, map, Observable, of, switchMap, take } from 'rxjs';

@Component({
  selector: 'selfai-platform-thumbnail',
  imports: [
    CommonModule,
    AutoFocusModule,
    InputSwitchModule,
    ButtonModule,
    InputTextModule,
    PaginatorModule,
    TooltipModule,
    MenuModule,
    TranslateModule,
    ConfirmDialogModule,
    ImgWithAuthComponent,
  ],
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, ConfirmationService],
})
export class ThumbnailComponent implements OnInit {
  @Input() workspace: Workspace;
  @Input() highlight: boolean;
  @Input() dashboard: Dashboard;
  @Input() workbookId: string;
  @Input() isPinned: boolean;

  @Output() dashboardSelect: EventEmitter<Dashboard> = new EventEmitter<Dashboard>();
  @Output() dashboardClone: EventEmitter<Dashboard> = new EventEmitter();
  @Output() dashboardDelete: EventEmitter<Dashboard> = new EventEmitter();

  private workspacePermission: PermissionForEntityFactory;
  protected readonly biEntityPermission = BiEntityPermission;

  menuMoreItems$: Observable<MenuItem[]>;

  thumbnailImgDefault = THUMBNAIL_IMAGE_DEFAULT;
  now = Date.now();

  isEditingNameDashboard: boolean;

  constructor(
    private readonly permissionService: BiPermissionService,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly chartPdfDownloadDomainService: ChartPdfDownloadDomainService,
    private readonly alertService: AlertService,
    private readonly workbookActionsService: WorkbookActionsService,
    private readonly dashboardActionsService: DashboardActionsService,
    private readonly router: Router,
    private readonly destroy$: DestroyService,
    private readonly translate: TranslateService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.workspacePermission = this.permissionService.getPermissionsForEntityFactory(this.workspace.permissions);

    this.menuMoreItems$ = combineLatest({
      cloneDashboard: this.translate.stream('msg.navbar.clone'),
      editDashboard: this.translate.stream('msg.navbar.edit'),
      deleteDashboard: this.translate.stream('msg.navbar.delete'),
      saveDashboardPdf: this.translate.stream('msg.navbar.saveDashboardPdf'),
      mainDashboard: this.translate.stream('msg.workbook-dashboard-list.table-actions.add-to-main'),
    }).pipe(
      map(({ ...translations }) => {
        return this.getMenuMoreItems(translations);
      }),
    );
  }

  hasPermission(permission: BiEntityPermission): boolean {
    return this.workspacePermission?.hasPermission(permission);
  }

  togglePin(checked: boolean): void {
    if (checked) {
      this.addPinDashboard();
    } else {
      this.deletePinDashboard();
    }
  }

  addPinDashboard(): void {
    this.workbookPinsDomainService
      .addDashboardToPins(this.workbookId, { name: this.dashboard.name, dashboardId: this.dashboard.id })
      .pipe(take(1))
      .subscribe();
  }

  deletePinDashboard(): void {
    this.workbookPinsDomainService
      .deleteDashboardFromPins(this.workbookId, this.dashboard.id)
      .pipe(take(1))
      .subscribe();
  }

  renameDashboard(): void {
    this.dashboardDomainService
      .updateDashboard({
        id: this.dashboard.id,
        name: this.dashboard.name,
        configuration: this.dashboard.configuration,
      })
      .pipe(
        take(1),
        switchMap(() => this.workbookPinsDomainService.loadPins(this.workbookId)),
      )
      .subscribe();

    this.isEditingNameDashboard = false;
  }

  startEditDashboardName(): void {
    this.isEditingNameDashboard = true;
  }

  private cloneDashboard(): void {
    this.dashboardClone.emit(this.dashboard);
  }

  private confirmDeleteDashboard(): void {
    this.confirmationService.confirm({
      message: convertMessageToHtml(this.translate.instant('msg.dashboard.delete.confirmation')),
      header: this.translate.instant('msg.dashboard.delete.title'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      accept: () => {
        this.dashboardDelete.emit(this.dashboard);
      },
    });
  }

  private editDashboard(): void {
    this.router.navigate(['/', BI_ROOT_ROUTE, 'workbook', this.workbookId], {
      queryParams: { dashboardId: this.dashboard.id },
    });
    this.workbookActionsService.setUpdateDashboardConfig({ view: true });
  }

  private downloadPdf(): void {
    this.chartPdfDownloadDomainService
      .downloadDashboardPdf('dashboard.pdf', this.dashboard.id)
      .pipe(
        take(1),
        catchError(() => {
          this.alertService.error(this.translate.instant('msg.dashboard.pdf-download.error'));

          return of();
        }),
      )
      .subscribe();
  }

  private getMenuMoreItems(
    translations: Record<
      'cloneDashboard' | 'editDashboard' | 'deleteDashboard' | 'saveDashboardPdf' | 'mainDashboard',
      string
    >,
  ): MenuItem[] {
    const items: MenuItem[] = [];

    if (this.hasPermission(BiEntityPermission.DASHBOARD_CREATE)) {
      items.push({
        label: translations.cloneDashboard,
        icon: 'pi pi-clone',
        command: () => this.cloneDashboard(),
      });
    }

    if (this.hasPermission(BiEntityPermission.DASHBOARD_EDIT)) {
      items.push({
        label: translations.editDashboard,
        icon: 'pi pi-pencil',
        command: () => this.editDashboard(),
      });
    }

    if (this.hasPermission(BiEntityPermission.DASHBOARD_DELETE)) {
      items.push({
        label: translations.deleteDashboard,
        icon: 'pi pi-trash',
        command: () => this.confirmDeleteDashboard(),
      });
    }

    if (this.hasPermission(BiEntityPermission.DASHBOARD_VIEW)) {
      items.push({
        label: translations.saveDashboardPdf,
        icon: 'pi pi-download',
        command: () => this.downloadPdf(),
      });
    }

    if (this.hasPermission(BiEntityPermission.WORKSPACE_METADATA_EDIT)) {
      items.push({
        label: translations.mainDashboard,
        icon: 'pi pi-home',
        command: () => this.addToMainPage(),
      });
    }

    return items;
  }

  private addToMainPage(): void {
    this.dashboardActionsService.addToMainDashboard(this.dashboard.id);
  }
}

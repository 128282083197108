<p-confirmPopup></p-confirmPopup>
<p-confirmDialog key="dialog"></p-confirmDialog>

<ng-template #toolbar>
  <selfai-platform-data-list-view-toolbar
    [layoutTypes]="layoutTypes"
    [orderByOptions]="orderByOptions"
    [groupActions]="groupActions"
    [columns]="columns"
    [actions]="actions"
    [pageSizes]="pageSizes"
  >
    <ng-content [ngProjectAs]="slotSelectors.toolbarActions" [select]="slotSelectors.toolbarActions"></ng-content>
  </selfai-platform-data-list-view-toolbar>
</ng-template>

<ng-container [ngSwitch]="selectedLayoutType$ | async">
  <selfai-platform-data-list-view-grid
    *ngSwitchCase="'grid'"
    [groupActions]="groupActions"
    [showEntityIcon]="showEntityIcon"
    [emptyMessage]="emptyMessage"
    [templates]="templates"
    [dataIsLazy]="dataIsLazy"
    [isFavoriteSupported]="isFavoriteSupported"
    [toolbar]="toolbar"
    (toggleFavorite)="toggleFavorite.emit($event)"
  ></selfai-platform-data-list-view-grid>
  <selfai-platform-data-list-view-table
    *ngSwitchDefault
    [columns]="columns"
    [groupActions]="groupActions"
    [showEntityIcon]="showEntityIcon"
    [isNameEditable]="isNameEditable"
    [emptyMessage]="emptyMessage"
    (renameItem)="renameItem.emit($event)"
    [templates]="templates"
    [dataIsLazy]="dataIsLazy"
    [isFavoriteSupported]="isFavoriteSupported"
    [globalFilterFields]="globalFilterFields"
    [toolbar]="toolbar"
    (toggleFavorite)="toggleFavorite.emit($event)"
  ></selfai-platform-data-list-view-table>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ImgWithAuthComponent } from '@selfai-platform/shared';
import { DataListViewComponent, DataListViewTemplateNameDirective } from '@selfai-platform/shell';
import { SkeletonModule } from 'primeng/skeleton';
import { WorkbookDashboardListComponent } from './dashboard-list.component';

@NgModule({
  imports: [
    CommonModule,
    DataListViewComponent,
    DataListViewTemplateNameDirective,
    SkeletonModule,
    TranslateModule,
    ImgWithAuthComponent,
    RouterModule.forChild([
      {
        path: '',
        component: WorkbookDashboardListComponent,
      },
    ]),
  ],
  declarations: [WorkbookDashboardListComponent],
  exports: [WorkbookDashboardListComponent],
})
export class WorkbookDashboardListComponentModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { ImgWithAuthComponent } from '@selfai-platform/shared';
import { DashboardListSelectorComponent } from './dashboard-list-selector.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    AutoFocusModule,
    TooltipModule,
    OverlayPanelModule,
    ImgWithAuthComponent,
  ],
  declarations: [DashboardListSelectorComponent],
  exports: [DashboardListSelectorComponent],
})
export class DashboardListSelectorComponentModule {}

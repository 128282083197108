import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  BiPermissionService,
  DashboardPin,
  PermissionForEntityFactory,
  WorkbookPinsDomainService,
  Workspace,
} from '@selfai-platform/bi-domain';
import { BiEntityPermission, DestroyService } from '@selfai-platform/shared';
import { BI_ROOT_ROUTE } from '@selfai-platform/shell';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs';
import { CountdownPipe } from './countdown.pipe';
import { SECONDS_TO_DELETE, WorkbookPinsDeleteService } from './workbook-pins-delete.service';

@Component({
    selector: 'selfai-platform-workbook-pins',
    imports: [CommonModule, RouterModule, DragDropModule, ButtonModule, CountdownPipe, TooltipModule, TranslateModule],
    templateUrl: './workbook-pins.component.html',
    styleUrls: ['./workbook-pins.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService, WorkbookPinsDeleteService]
})
export class WorkbookPinsComponent implements OnInit {
  @Input() workbookId: string;
  @Input() workspace: Workspace;

  biRouteRoot = BI_ROOT_ROUTE;
  secondToDelete = SECONDS_TO_DELETE;

  pins$: Observable<DashboardPin[]>;
  activeDashboardId$: Observable<string>;
  markAsDeletedMap = this.workbookPinsDeleteService.markAsDeletedMap;

  private workspacePermission: PermissionForEntityFactory;
  protected readonly biEntityPermission = BiEntityPermission;

  constructor(
    private readonly permissionService: BiPermissionService,
    private readonly route: ActivatedRoute,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
    private readonly workbookPinsDeleteService: WorkbookPinsDeleteService,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.activeDashboardId$ = this.route.queryParamMap.pipe(
      map((params) => params.get('dashboardId')),
      distinctUntilChanged(),
      filter(Boolean),
      takeUntil(this.destroy$),
    );
    this.workbookPinsDomainService.loadPins(this.workbookId).pipe(take(1)).subscribe();
    this.pins$ = this.workbookPinsDomainService.getPins(this.workbookId);
    this.workspacePermission = this.permissionService.getPermissionsForEntityFactory(this.workspace.permissions);
  }

  hasPermission(permission: BiEntityPermission): boolean {
    return this.workspacePermission?.hasPermission(permission);
  }

  deletePin(dashboardId: string): void {
    this.workbookPinsDomainService.deleteDashboardFromPins(this.workbookId, dashboardId).pipe(take(1)).subscribe();
  }

  dropPin(event: CdkDragDrop<string[]>): void {
    this.pins$.pipe(take(1)).subscribe((pins) => {
      const newPins = [...pins];
      moveItemInArray(newPins, event.previousIndex, event.currentIndex);
      this.workbookPinsDomainService.addAllPins(this.workbookId, newPins).pipe(take(1)).subscribe();
    });
  }

  markAsDeleted(dashboardId: string): void {
    this.workbookPinsDeleteService.markAsDeleted(this.workbookId, dashboardId);
  }

  undoDelete(dashboardId: string): void {
    this.workbookPinsDeleteService.cancelDelete(dashboardId);
  }
}

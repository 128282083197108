import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  AdditionalParamFormGroup,
  ESparkClusterConfig,
  ESparkCLusterType,
  EWorkflowRunnerParamToRun,
  EWorkflowSelectionMode,
  WorkflowInfo,
  WorkflowRunnerAdditionalParam,
  WorkflowRunnerClusterConfiguration,
  WorkflowRunnerFormGroup,
  WorkflowRunnerLegacyData,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { isEmpty } from 'lodash';
import { Table } from 'primeng/table';
import { filter, take, takeUntil, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DialogHelperService } from '../../../../dialog';
import { WorkflowPresets } from '../../../../workflow-presets';
import { normalizeToLegacyDataWorkflowRunner } from '../../../converters/workflow-runner.normalizer';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { SaveConfirmationService } from '../../../services/save-confirmation.service';
import { SelectionStoreService } from '../../../services/selection-store.service';
import { AbstractCubeDialogFormWithTableComponent } from '../../abstract-cube-dialog-form-with-table.component';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';
import { WorkflowRunnerComponentService } from './workflow-runner-component.service';

export interface IWorkflowParamToRunOptions {
  label: string;
  value: EWorkflowRunnerParamToRun;
}

@Component({
  selector: 'selfai-platform-workflow-runner',
  templateUrl: './workflow-runner.component.html',
  styleUrls: ['./workflow-runner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    DialogHelperService,
    SelectionStoreService,
    SaveConfirmationService,
    WorkflowRunnerComponentService,
    DialogHeaderService,
  ],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          'max-height': '0px',
        }),
      ),
      state(
        'active',
        style({
          'max-height': '1000px',
        }),
      ),
      transition('* <=> *', animate('1000ms')),
    ]),
  ],
  standalone: false,
})
export class WorkflowRunnerComponent extends AbstractCubeDialogFormWithTableComponent implements OnInit {
  protected readonly EClusterConfigurationOptions = ESparkClusterConfig;

  public sizingTableName = 'CreateTrigger';
  public workflowListOptions$ = this.workflowRunnerComponentService.workflowList$.pipe(
    tap((workflowList) => {
      const selectedWorkflow = workflowList.find(
        (workflow) =>
          workflow.id === this.workflowRunnerComponentService.value.data.workflowId ||
          workflow.name === this.workflowRunnerComponentService.value.data.workflowName,
      );

      if (selectedWorkflow) {
        this.form.get('workflow').setValue(selectedWorkflow);
      }
    }),
  );
  public clusterConfig: WorkflowRunnerClusterConfiguration;
  public workflowRunnerClusterOptions = [
    { name: 'Default', value: ESparkClusterConfig.Default },
    { name: 'Override', value: ESparkClusterConfig.Override },
  ];
  public paramToRunOptions: IWorkflowParamToRunOptions[] = [
    { label: 'UUID', value: EWorkflowRunnerParamToRun.UUID },
    { label: 'Name', value: EWorkflowRunnerParamToRun.NAME },
  ];
  public workflowSelectionMode = [
    {
      label: this.translateService.instant(
        'workflow.cubes.workflow-runner.form.option.workflow-selection-mode.workflow-list',
      ),
      value: EWorkflowSelectionMode.WORKFLOW_LIST,
    },
    {
      label: this.translateService.instant(
        'workflow.cubes.workflow-runner.form.option.workflow-selection-mode.custom-expression',
      ),
      value: EWorkflowSelectionMode.CUSTOM_EXPRESSION,
    },
  ];

  override itemsMap = new Map<string, FormGroup<AdditionalParamFormGroup>>();
  override form: FormGroup<WorkflowRunnerFormGroup> = this.workflowRunnerComponentService.getFormGroup();
  override initialItem: WorkflowRunnerAdditionalParam = {
    id: '',
    name: '',
    query: '',
    notEmpty: false,
    isArray: false,
  };

  get dataForWorkflow(): WorkflowRunnerLegacyData {
    return normalizeToLegacyDataWorkflowRunner({
      workflowId: this.form.controls.workflowId.value || null,
      workflowName: this.form.controls.workflowName.value || null,
      stopFlowBeforeRun: this.form.controls.stopFlowBeforeRun.value || false,
      sparkCluster: this.clusterConfig,
      parameters: this.items.map((param) => param.value as WorkflowRunnerAdditionalParam),
    });
  }

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;
  @ViewChild('tableColRef') tableColRef!: ElementRef<HTMLElement>;

  constructor(
    private readonly workflowRunnerComponentService: WorkflowRunnerComponentService,
    injector: Injector,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly translateService: TranslateService,
  ) {
    super(injector);
  }

  override addItem(): void {
    const id = uuidv4();
    const item = { ...this.initialItem, id };
    const form = this.mapItemToFormGroup(item);

    this.itemsMap.set(id, form);
  }

  public ngOnInit(): void {
    this.initCustomHeaderComponent();
    if (!isEmpty(this.workflowRunnerComponentService.value.data.sparkCluster)) {
      this.setCustomClusterConfig(
        this.workflowRunnerComponentService.value.data.sparkCluster as WorkflowRunnerClusterConfiguration,
      );
    }
    this.workflowRunnerComponentService
      .getParametersFormGroups()
      .pipe(take(1), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((items) => {
        items.forEach((formGroup) => this.itemsMap.set(formGroup.controls.id.value, formGroup));
        this.markFormAsInitialized();
      });

    this.form
      .get('workflow')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((workflow: WorkflowInfo) => {
        if (workflow) {
          this.setWorkflowControls(workflow);
        }
        this.form
          .get('workflowParamToRun')
          ?.setValue(
            this.workflowRunnerComponentService.value.data.workflowId
              ? EWorkflowRunnerParamToRun.UUID
              : EWorkflowRunnerParamToRun.NAME,
          );
      });

    this.form
      .get('workflowParamToRun')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const workflowSelectionMode = this.form.get('workflowSelectionMode').value;
        switch (workflowSelectionMode) {
          case EWorkflowSelectionMode.WORKFLOW_LIST:
            const workflow = this.form.get('workflow').value;
            if (workflow) {
              this.setWorkflowControls(workflow);
            }
            break;
          case EWorkflowSelectionMode.CUSTOM_EXPRESSION:
            const workflowExpression = this.form.get('workflowExpression').value;
            this.setWorkflowControls({ id: workflowExpression, name: workflowExpression } as WorkflowInfo);
        }
      });

    this.form
      .get('workflowExpression')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.setWorkflowControls({ id: value, name: value } as WorkflowInfo);
      });

    this.form
      .get('workflowSelectionMode')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.clearWorkflowControl();
      });
  }

  public setCustomClusterConfig(clusterConfig: WorkflowRunnerClusterConfiguration | WorkflowPresets): void {
    this.clusterConfig = clusterConfig as WorkflowRunnerClusterConfiguration;
    this.clusterConfig.id = uuidv4();
    this.clusterConfig.clusterType = clusterConfig.clusterType as ESparkCLusterType;
  }

  public mapItemToFormGroup(item: WorkflowRunnerAdditionalParam): UntypedFormGroup {
    return this.workflowRunnerComponentService.mapItemToFormGroup(item);
  }

  public setWorkflowControls(workflow: WorkflowInfo): void {
    const paramToRun = this.form.get('workflowParamToRun')?.value;
    switch (paramToRun) {
      case EWorkflowRunnerParamToRun.UUID:
        this.form.get('workflowId').setValue(workflow.id);
        this.form.get('workflowName').setValue(null);
        break;
      case EWorkflowRunnerParamToRun.NAME:
        this.form.get('workflowId').setValue(null);
        this.form.get('workflowName').setValue(workflow.name);
        break;
      default:
        break;
    }
  }

  public clearWorkflowControl(): void {
    this.form.get('workflowId').setValue('');
    this.form.get('workflowName').setValue('');
    this.cdr.detectChanges();
  }

  private initCustomHeaderComponent(): void {
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      this.translateService.instant('workflow.cubes.workflow-runner.modal-header'),
      this.closeDialog.bind(this),
      this.onClickSave.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  protected readonly EWorkflowSelectionMode = EWorkflowSelectionMode;
}

<div selfaiMenuLayoutClass>
  <selfai-platform-header [logoPath]="logoPath"></selfai-platform-header>
  <selfai-platform-main-menu></selfai-platform-main-menu>
  <div class="layout-main">
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<bi-shared-page-is-ready></bi-shared-page-is-ready>
<selfai-platform-page-loader></selfai-platform-page-loader>

import { DestroyRef, Directive, HostBinding, inject, OnInit } from '@angular/core';
import { MenuService } from './menu.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenuMode } from './menu-mode.enum';

@Directive({
  selector: '[selfaiMenuLayoutClass]',
  standalone: true
})
export class MenuLayoutClassDirective implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @HostBinding('class.layout-wrapper') layoutWrapper = true;
  @HostBinding('class.layout-menu-light') layoutMenuLight = true;
  @HostBinding('class.layout-topbar-light') layoutTopbarLight = true;
  @HostBinding('class.layout-static') layoutStatic = false;
  @HostBinding('class.layout-horizontal') layoutHorizontal = false;
  @HostBinding('class.layout-static-active') layoutStaticActive = false;
  @HostBinding('class.layout-mobile-active') layoutMobileActive = false;

  constructor(private menuService: MenuService) {}

  ngOnInit() {
    this.menuService.menuMode$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(menuMode => {
      this.layoutStatic = menuMode === MenuMode.VERTICAL;
      this.layoutHorizontal = menuMode === MenuMode.HORIZONTAL;
    });

    this.menuService.isDesktopMenuOpened$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(isOpened => {
      this.layoutStaticActive = isOpened;
    });

    this.menuService.isMobileMenuOpened$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(isOpened => {
      this.layoutMobileActive = isOpened;
    });
  }
}

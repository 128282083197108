<selfai-platform-data-list-view
  [actions]="actions$ | async"
  [groupActions]="groupActions$ | async"
  [columns]="columns"
  [showEntityIcon]="true"
  [dataIsLazy]="true"
  [emptyMessage]="'msg.workbook-dashboard-list.table-empty' | translate"
  (renameItem)="saveName($event)"
  (toggleFavorite)="toggleFavorite($event)"
>
  <ng-template selfaiPlatformDataListViewTemplateName="gridItemPreview" let-item>
    <a
      [routerLink]="item.routerLinkToItem?.routerLink"
      [queryParams]="item.routerLinkToItem?.queryParams"
      class="dashboard-preview square flex"
    >
      @if (item.imageThumbnailUrl) {
        <selfai-platform-img-with-auth [src]="item.imageThumbnailUrl" />
      } @else {
        <img [src]="thumbnailImgDefault" />
      }
    </a>
  </ng-template>
  <ng-template selfaiPlatformDataListViewTemplateName="gridItemPreviewSkeleton">
    <div class="dashboard-preview square flex">
      <p-skeleton width="100%" height="100%"></p-skeleton>
    </div>
  </ng-template>
</selfai-platform-data-list-view>

import { DESKTOP_MIN_LAYOUT_SIZE } from "./consts";

export type Layout = 'desktop' | 'mobile';

export function isDesktop(width: number): boolean {
  return width > DESKTOP_MIN_LAYOUT_SIZE;
}

export function detectLayout(width: number): Layout {
  return isDesktop(width) ? 'desktop' : 'mobile';
}

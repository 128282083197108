import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'selfai-platform-scrollable-x-view',
  imports: [],
  templateUrl: './scrollable-x-view.component.html',
  styleUrl: './scrollable-x-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollableXViewComponent {
  @ViewChild('content') content: ElementRef;
  @ViewChild('prevBtn') prevBtn: ElementRef;
  @ViewChild('nextBtn') nextBtn: ElementRef;

  backwardIsDisabled: boolean = true;
  forwardIsDisabled: boolean = false;

  updateButtonState() {
    const content = this.content.nativeElement;
    const { scrollLeft, scrollWidth } = content;
    const width = DomHandler.getWidth(content);

    this.backwardIsDisabled = scrollLeft === 0;
    this.forwardIsDisabled = parseInt(scrollLeft) === scrollWidth - width;
  }

  onScroll(event: HTMLElementEventMap['scroll']) {
    this.updateButtonState();

    event.preventDefault();
  }

  navBackward() {
    const content = this.content.nativeElement;
    const width = DomHandler.getWidth(content) - this.getVisibleButtonWidths();
    const pos = content.scrollLeft - width;
    content.scrollLeft = pos <= 0 ? 0 : pos;
  }

  navForward() {
    const content = this.content.nativeElement;
    const width = DomHandler.getWidth(content) - this.getVisibleButtonWidths();
    const pos = content.scrollLeft + width;
    const lastPos = content.scrollWidth - width;

    content.scrollLeft = pos >= lastPos ? lastPos : pos;
  }

  getVisibleButtonWidths() {
    return [this.prevBtn?.nativeElement, this.nextBtn?.nativeElement].reduce(
      (acc, el) => (el ? acc + DomHandler.getWidth(el) : acc),
      0,
    );
  }
}

import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'selfai-platform-img-with-auth',
  imports: [AsyncPipe],
  templateUrl: './img-with-auth.component.html',
  styleUrl: './img-with-auth.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgWithAuthComponent implements OnChanges {
  @Input() src: string;
  @Input() classNames: string;
  @Input() alt: string;

  src$: Observable<string>;

  constructor(private http: HttpClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src']) {
      this.src$ = this.transformToBlob(changes['src'].currentValue).pipe(map((blob) => URL.createObjectURL(blob)));
    }
  }

  private transformToBlob(src: string): Observable<Blob> {
    return this.http.get(src, { responseType: 'blob' });
  }
}
